@import "../../assets/scss/variables.scss";

.omnibus_list_block {
  margin: 45px 0 56px;
  .omnibus_list_title {
    color: $color-skyblue;
    letter-spacing: 3.2px !important;
    text-align: center;
    padding-bottom: 22px;
  }
}
.au_cost {
  color: $color-white !important;
  margin: 0 54px 0 0 !important;
}
.add_cart_btn {
  max-width: 297px;
  width: 100%;
  padding: 14px !important;
  font-size: 22px !important;
  line-height: 27px !important;
  border-radius: 8px !important;
  letter-spacing: 0px !important;
}
.card_block {
  padding: 12px 12px 20px;
  border: 1px solid rgba($color: $color-skyblue, $alpha: 0.56);
  border-radius: $box-Radius !important;
  box-shadow: 2px 4px 16px 0px rgba($color: #000000, $alpha: 0.1) !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.primary_exam_title {
  margin: 0px 0px 20px 0 !important;
}
.primary_exam_content {
  font-family: "Inter"!important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: $colorDarkGray !important;
}
.list_btn {
  width: 204px;
}
.view_btn {
  // width: 227px;
}
.sub_omnibus {
  font-weight: 400 !important;
  color: $color-gray;
  margin-left: 27px !important;
}

.addcart_title {
  font-size: 23px !important;
  font-weight: $font_regular !important;
  line-height: 33px !important;
  padding: 36px 0 10px;
}
.test_cost {
  font-size: 20px !important;
  line-height: 24px !important;
  color: $color-lightblue !important;
  padding-bottom: 36px;
  font-weight: $font_regular !important;
}
.addcart_text {
  font-size: 50px!important;
  font-weight: $font_bold !important;
  line-height: 44px !important;
  color: $color-skyblue !important;
}

// userprofile
.profile_bg {
  background-color: $color-white-shade;
  border-radius: 16px;
  padding: 33px 46px 55px;
}
.cat-img {
  max-height: 300px;
  width: 100%;
}
.breadcrumb-wrap {
      margin-bottom: 16px !important;
  .MuiBreadcrumbs-separator {
    font-size: 20px;
     }
  li{
    font-size: 16px;
    line-height: 19px;
  }
  li p {
    font-family: $primary_font;
    color: #fff;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
  }
}



// responsive

@media (max-width: 1535px) {
}

@media (max-width: 1199px) {
  .view_btn {
    width: 190px;
  }
  .addcart_title {
    font-size: 20px !important;
    line-height: 30px !important;
    padding: 32px 0 10px;
  }
  .checkmarkIcon{
    & svg{
      width: 42px;
      height: 42px;
    }
  }
}

@media (max-width: 899px) {
  .add_cart_btn {
    max-width: 250px;
    padding: 12px !important;
    font-size: 18px !important;
    line-height: 24px !important;
    .MuiButton-startIcon{
      margin-right: 10px;
      & svg{
        width: 24px;
        height: 24px;
      }
    }
  }
  .primary_exam_title {
    margin: 0px 0px 16px 0 !important;
  }
  .primary_exam_content {
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .test_cost {
    font-size: 20px !important;
    line-height: 24px !important;
    padding-bottom: 25px;
  }
  .addcart_text {
    font-size: 40px!important;
    line-height: 35px !important;
  }
  .breadcrumb-wrap {
    li{
      font-size: 14px;
      line-height: 17px;
    }
    li p {
      font-size: 14px;
      line-height: 17px;
    }
  }
  .addcart_title {
    font-size: 18px !important;
    line-height: 28px !important;
    padding: 28px 0 10px;
  }
  .checkmarkIcon{
    & svg{
      width: 32px;
      height: 32px;
    }
  }
}

@media (max-width: 599px) {
  .primary_exam_title {
    margin: 0px 0px 12px 0 !important;
  }
  .primary_exam_content {
    font-size: 14px !important;
    line-height: 18px !important;
  }
  .au_cost {
    margin: 0 40px 0 0 !important;
  }
  .test_cost {
    font-size: 16px !important;
    line-height: 20px !important;
    padding-bottom: 20px;
  }
  .addcart_text {
    font-size: 30px!important;
    line-height: 25px !important;
  }
  .breadcrumb-wrap {
    li{
      font-size: 12px;
      line-height: 15px;
    }
    li p {
      font-size: 12px;
      line-height: 15px;
    }
  }
  .add_cart_btn{
    .MuiButton-startIcon{
      & svg{
        width: 22px;
        height: 22px;
      }
    }
  }
  .addcart_title {
    font-size: 16px !important;
    line-height: 26px !important;
    padding: 25px 0 10px;
  }
  .checkmarkIcon{
    & svg{
      width: 26px;
      height: 26px;
    }
  }
}

@media (max-width: 475px) {
  .add_cart_btn {
    max-width: 200px;
    font-size: 16px !important;
    line-height: 22px !important;
    padding: 10px !important;
  }
  .au_cost {
    margin: 0 25px 0 0 !important;
  }
  .addcart_text {
    font-size: 20px!important;
    line-height: 18px !important;
  }
  .addcart_title {
    font-size: 14px !important;
    line-height: 24px !important;
    padding: 22px 0 10px;
  }
}