@import '../../../assets/scss/variables.scss';

.exam_type_box {
  max-width: 318px;
  width: 100%;
  .type_block_wraper {
    background: #fff;
    padding: 26px 49px 30px 46px;
    border-radius: 20px;
    box-shadow: 0px 6px 20px 0px rgba($color: #000000, $alpha: 0.1);
  }
}
.type_wraper {
  margin-top: 20px;
  ul {
    list-style-image: url('../../../assets/images/icons/checkmark.svg');
    padding-left: 22px;
    margin: 0;
    li {
      font-size: 20px;
      padding-left: 8px;
      line-height: 45px;
      font-weight: 500;
      color: #373737;
    }
  }
}
.about_course {
  ul {
    list-style-image: url('../../../assets/images/icons/blueCheckMark.svg');
    padding-left: 22px;
    margin: 0;
    column-count: 2;
    column-gap: 70px;
    li {
      font-size: 16px;
      padding: 5px 0 5px 8px;
      line-height: 20px;
      font-weight: 400;
      color: #101010;
    }
  }
}
body {
  .modal-deactive {
    .MuiPaper-root {
      padding: 36px 36px 41px 36px !important;
      max-width: 894px;
    }
    h1 {
      font-size: 50px;
      line-height: 61px;
      color: #41a6e9;
      margin: 0px 27px 0px;
    }
    p {
      font-size: 23px;
      line-height: 33px;
      color: #373737;
      font-weight: 500;
      margin-bottom: 36px;
    }
    .btn-wrap {
      gap: 18px;
      button {
        width: calc(50% - 9px);
        font-size: 18px;
        line-height: 22px;
        border-radius: 10px;
        color: #fff;
        text-transform: capitalize;
        font-weight: 500;
        padding: 14px 5px;
      }
      .cancle {
        background-color: #373737;
      }
      .approve {
        background-color: #d7373f;
      }
    }
  }
}

// responsive
@media (max-width: 1199px) {
  .exam_type_box {
    max-width: 300px;
    .type_block_wraper {
      padding: 26px 49px 30px 46px;
    }
  }
  .type_wraper {
    ul {
      padding-left: 20px;
      li {
        font-size: 18px;
        line-height: 40px;
      }
    }
  }
}

@media (max-width: 991px) {
  body {
    .modal-deactive {
      .MuiPaper-root {
        width: 80%;
        margin: 20px auto;
      }
      h1 {
        font-size: 35px;
        line-height: 40px;
      }
      p {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}

@media (max-width: 899px) {
  .exam_type_box {
    max-width: 280px;
    .type_block_wraper {
      padding: 26px 49px 25px 40px;
    }
  }
  .type_wraper {
    ul {
      padding-left: 18px;
      li {
        font-size: 16px;
        line-height: 36px;
      }
    }
  }
  .about_course {
    ul {
      padding-left: 24px;
      column-count: 1;
    }
  }
}

@media (max-width: 767px) {
  body {
    .modal-deactive {
      .MuiPaper-root {
        padding: 20px !important;
      }
      h1 {
        font-size: 24px;
        line-height: 30px;
      }
      p {
        font-size: 18px;
        line-height: 30px;
      }
      .btn-wrap {
        button {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 599px) {
  .exam_type_box {
    max-width: 250px;
    .type_block_wraper {
      padding: 26px 40px 20px 38px;
    }
  }
  .type_wraper {
    ul {
      padding-left: 16px;
      li {
        font-size: 14px;
        line-height: 32px;
      }
    }
  }
  body {
    .modal-deactive {
      .btn-wrap {
        flex-direction: column;
        button {
          width: 100%;
        }
      }
    }
  }
}
