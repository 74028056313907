@import "./variables.scss";

* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: $primary_font !important;
}
img {
  max-width: 100%;
  height: auto;
}
a {
  font-family: $primary_font !important;
  text-decoration: none;
}
html {
  scroll-behavior: smooth;
}
.main_container {
  max-width: 1148px;
  padding: 0 30px;
  margin: 0 auto;
}

.flex_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sub_container {
  max-width: 1410px;
  padding: 0 30px;
  margin: 0 auto;
}

// BUTTON
.blue_btn {
  background: linear-gradient(
    90deg,
    rgba(74, 181, 252, 1) 0%,
    rgba(64, 92, 251, 1) 100%
  );
  color: $color-white !important;
  padding: 18px 36px !important;
  font-family: $primary_font !important;
  font-size: 20px !important;
  line-height: 24px !important;
  border-radius: $bRadius !important;
  text-transform: none !important;
  letter-spacing: 0px !important;
}
.br_btn {
  border-radius: $btn-Radius !important;
  font-size: 24px !important;
  line-height: 29px !important;
  font-weight: $font_regular !important;
}
.btn {
  background-color: $color-skyblue !important;
  color: $color-white !important;
  padding: 8px 15px !important;
  font-family: $primary_font !important;
  font-size: 20px !important;
  line-height: 56px !important;
  border-radius: 6px !important;
  text-transform: none !important;
}
.email_link {
  color: $color-blue !important;
  text-decoration: none !important;
}

// EDIC CICM & PRIMARY SECTION BACKGROUND

.edicC_banner {
  background-image: url("../images/BGC.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 70px 0;
}

.edic_banner {
  // background-image:url("../images/BG.jpg") ;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 72px 0;
  color: $color-white;
}

.catergory_description > p {
  margin: 0;
}

// SAND CLOCK SECTION BACKGROUND
.sand_bg {
  background: linear-gradient(
    90deg,
    rgba(74, 181, 252, 1) 0%,
    rgba(64, 92, 251, 1) 100%
  );
}

.my_cart_title {
  font-size: 49px !important;
  font-weight: $font_bold !important;
  line-height: 59px !important;
}

.categoryBlock {
  position: relative;
  text-align: center;
  .categoryDetails {
    background-color: $color-white;
    box-shadow: 1px 1px 10px 10px rgba($color: $color-black, $alpha: 0.04);
    padding: 27px 21px 32px;
    border-radius: $box-Radius;
    text-align: left;
    position: absolute;
    top: 38px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s;
    .categoryNameBox {
      padding: 10px 12px 9px 10px;
      background: linear-gradient(90deg, $color-lightblue 0%, $color-blue 100%);
      border-radius: $bRadius;
      text-align: center;
      max-width: 254px;
      .categoryName {
        font-weight: $font_medium;
        color: $color-white;
        line-height: 24px;
      }
    }
    .viewdetailsBtn {
      padding: 15px 20px;
      max-width: 100%;
      height: 54px;
    }
  }
  &:hover .categoryDetails {
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }
}

.about_title {
  font-size: 31.36px !important;
  font-weight: 600 !important;
  line-height: 43.9px !important;
  color: $color-skyblue !important;
}
.course_block {
  display: flex;
  align-items: center;
  padding-bottom: 14px;
  .checkmarkIcon {
    margin-right: 9px;
    path {
      fill: $color-blue;
    }
  }
  .type_name {
    font-size: 16px !important;
    font-weight: 400;
    line-height: 22.4px !important;
    color: $colorBlack;
    padding: 0;
  }
}

// DIVIDER
.divider {
  border-color: $color-light-gray !important;
  opacity: 0.12;
  margin: 0px -9px !important;
  border-bottom-width: 0.5px !important;
}
.divider_blue {
  border-color: $color-lightblue !important;
  border-bottom-width: 0.5px !important;
}

.valid_star {
  position: absolute;
  top: 0px;
}

.input_box_otp_wrapper {
  width: 200px;
  display: flex;
  justify-content: space-between;
  // .input-box-otp {
  input {
    border: 0px;
    padding-left: 0px;
    border-bottom: 1px solid #373737;
    border-radius: 0px;
    color: #707070;
    font-family: "Montserrat", sans-serif;
    line-height: 19px;
    outline: none !important;
    font-size: 20px;
  }
  // }
}
.text-danger {
  color: #c01515 !important;
  font-size: 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

// show hide styling
.hide-1279 {
  @media (max-width: 1279px) {
    display: none !important;
  }
}

.hide-959 {
  @media (max-width: 959px) {
    display: none !important;
  }
}

.show-1279 {
  display: none !important;
  @media (max-width: 1279px) {
    display: inherit !important;
  }
}
.show-959 {
  display: none !important;
  @media (max-width: 959px) {
    display: inherit !important;
  }
}

.show-959-flex {
  display: none !important;
  @media (max-width: 959px) {
    display: flex !important;
  }
}

.phoneNumber {
  .form-control {
    width: 100%;
    border-radius: 8px;
    &:focus {
      border-color: #d4d4d4;
      box-shadow: none;
    }
    &:disabled {
      border: none;
      background: transparent;
    }
  }
  .country-list {
    text-align: start;
    .country-name {
      color: #000000;
    }
  }
}

.MuiTypography-root.field-error {
  color: #c01515;
  margin: 4px 14px 0;
  font-size: 14px;
  line-height: 19px;
  padding: 0px;
  font-weight: 500;
  text-align: left;
}
// responsive
// @media(max-width:1690px){
//   .blue_btn{
//     font-size: 18px !important;
//     padding: 15px !important;
//   }
// }
// @media(max-width:1552px){
//   .blue_btn{
//     font-size: 16px !important;
//   }
// }
// @media(max-width:1417px){
//   .blue_btn{
//     font-size: 14px !important;
//     padding: 12px !important;
//   }
// }

@media (max-width: 1199px) {
  .edicC_banner {
    padding: 60px 0;
  }
  .blue_btn {
    font-size: 18px !important;
    padding: 15px !important;
  }
  .br_btn {
    font-size: 20px !important;
    line-height: 26px !important;
    padding: 16px 30px !important;
  }
  .edic_banner {
    padding: 50px 0 40px;
  }
  .my_cart_title {
    font-size: 40px !important;
    line-height: 50px !important;
  }
  .text-danger {
    font-size: 18px !important;
  }
  .about_title {
    font-size: 28px !important;
    line-height: 38px !important;
  }
}

@media (max-width: 899px) {
  .edicC_banner {
    padding: 50px 0;
  }
  .blue_btn {
    font-size: 16px !important;
  }
  .br_btn {
    font-size: 18px !important;
    line-height: 24px !important;
    padding: 14px 26px !important;
  }
  .edic_banner {
    padding: 44px 0 34px;
  }
  .my_cart_title {
    font-size: 30px !important;
    line-height: 40px !important;
  }
  .categoryBlock {
    .categoryDetails {
      top: 30px;
      padding: 25px 20px;
      .categoryNameBox {
        .categoryName {
          line-height: 18px;
        }
      }
      .viewdetailsBtn {
        padding: 10px;
        height: 40px;
      }
    }
  }
  .text-danger {
    font-size: 16px !important;
  }
  .about_title {
    font-size: 26px !important;
    line-height: 36px !important;
  }
}

@media (max-width: 599px) {
  .edicC_banner {
    padding: 40px 0;
  }
  .blue_btn {
    font-size: 14px !important;
    padding: 12px !important;
  }
  .br_btn {
    font-size: 16px !important;
    line-height: 22px !important;
    padding: 12px 20px !important;
  }
  .edic_banner {
    padding: 38px 0 28px;
  }
  .my_cart_title {
    font-size: 20px !important;
    line-height: 30px !important;
  }
  .text-danger {
    font-size: 14px !important;
  }
  .about_title {
    font-size: 24px !important;
    line-height: 34px !important;
  }
}

@media (max-width: 375px) {
  .edicC_banner {
    padding: 30px 0;
  }
  .br_btn {
    font-size: 14px !important;
    line-height: 20px !important;
    padding: 12px 16px !important;
  }
}
