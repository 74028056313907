//  Fonts

$primary_font:'Montserrat', sans-serif;
$font_family:'Lato', sans-serif;

$font_light:300;
$font_regular:400;
$font_medium:500;
$font_semibold:600;
$font_bold:700;
$font_black:900;


/* colors */

$color-black: #000000; 
$color-white: #ffffff;
$color-white-shade: #F7F7F7;
$color-gray: #B0B0B0;
$color-light-gray:#707070;
$color-light-black: #373737;
$color-blue: #405CFB;
$color-skyblue: #41A6E9;
$color-lightblue:#4AB5FC;
$color-red: #FF0000;
$bg-color-black:#2E2E2E;
$bg-color-gray:#F2F2F2;
$colorDarkGray:#6A6A6A;
$colorBlack:#101010;


/* Border Radius */
$btn-Radius: 37px; 
$box-Radius: 20px; 
$bRadius: 10px; 