@import "../../../assets/scss/variables.scss";

.footer_section {
  background-color: $bg-color-gray;
  font-size: 16px;
  font-weight: 400;
  padding: 18px 0 20px;
  line-height: 20px;
  color: $color-light-black;
  .email_data {
    line-height: 19px;
    margin: 28px 0 26px;
  }
  .payment_notice{
    margin-bottom: 22px;
  }
  .payment_card_section{
    display: flex;
    align-items: center;
  .payment_card{
    padding: 0;
    display: flex;
    &:not(:last-child){
      padding-right: 16px;
    }
  }
}
  .footer_link_section {
    display: flex;
    flex-flow: column !important;
    .MuiButton-root{
      text-transform: capitalize;
      padding: 0;
      justify-content: left;
      &:hover{
        background-color: transparent;
      }
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
    .footer_navlink {
      text-decoration: none;
      color: $color-light-black;
      line-height: 19px;
      font-size: 16px;
      font-weight: $font_regular;
      white-space: nowrap;
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }
  .sign-up_title {
    color: $color-skyblue;
    padding-bottom: 26px;
  }
   .sub_btn {
    margin-top: 11px;
    // width: 370px;
    width: 100%;
    padding: 14px 0 !important;
    letter-spacing: 0.36px;
    font-size: 18px!important;
    line-height: 22px!important;
  }
  .social_links{
    margin-top: 60px;
    .social_link{
      padding: 0;
    &:not(:last-child){
      padding-right: 22px;  
    }}
    & :hover{
      background: none;
    }
  }
  .copyright_content{
      color: $color-light-gray;
      text-align: center;
      padding-top: 50px;
  }
}

// responsive

@media (max-width:1199px){
  .footer_section {
    .footer_link_section {
      .MuiButton-root{
        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }
    .sub_btn {
      font-size: 16px!important;
      line-height: 20px!important;
    }
  }
}
@media (max-width:899px){
  .footer_section {
    .sub_btn {
      line-height: 16px!important;
    }
    .footer_link_section{
      .footer_navlink {
        &:not(:last-child) {
          margin-bottom: 35px;
        }
      }
    }
  }
}

@media (max-width:599px){
  .footer_section {
    .footer_content{ 
      justify-content: center;
      text-align: center;
    }
    .payment_card_section{
      justify-content: center;
    }
    .footer_link_section {
      .MuiButton-root{
        justify-content: center;
      }
    }
  }
}