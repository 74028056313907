@import "../../../assets/scss/variables.scss";

b {
  font-weight: 600;
}
span.read-or-hide {
  font-weight: 600;
  color: #405cfb;
  text-decoration: underline;
  cursor: pointer;
}
.slider-wrap {
  position: relative;
  background: $bg-color-black;
  /* min-height: 814px; */
  padding: 100px 0;
  .banner-img-wrap {
    margin-right: -72px;
  }
  ul.slick-dots {
    position: absolute;
    bottom: -54px;
  }
  .slick-dots li {
    margin: 0px 9.5px;
    width: 21px;
    height: 14px;
    padding: 0px;
  }
  .slick-slide img {
    width: 100%;
  }
  .slick-dots li button {
    width: 14px;
    height: 14px;
    padding: 0px;
    margin: auto;
    background-color: #c0c0c0;
    border-radius: 50%;
    transition: all 0.3s;
    will-change: width;
  }
  .slick-dots li button:before {
    content: "";
  }
  .slick-dots li.slick-active button {
    background-color: #41a6e9;
    border-radius: 7px;
    width: 21px;
  }
  .banner_section {
    color: $color-white;
    padding: 20px 0;
    .main_container {
      display: block;
    }
    .btn_wrap {
      padding-top: 63px;
      .sample_test {
        background: $color-white;
        border: 1px solid $color-lightblue;
        padding: 18px 32px !important;
      }
      .sample_text {
        background: linear-gradient(
          90deg,
          rgba(74, 181, 252, 1) 0%,
          rgba(64, 92, 251, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .avtar_trust_block {
      padding-top: 82px;
      .trust_block {
        display: flex;
        align-items: center;
        .MuiAvatarGroup-root {
          margin-left: 21.6px;
          flex-direction: initial;
        }
        .img_avtar {
          width: 51px;
          height: 51px;
          margin-left: -21.6px;
        }
      }
    }
  }
}
.our-app-wrap {
  padding: 28px 0px 333px 0px;
  position: relative;
  span {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    color: #373737;
    padding-left: 12px;
  }
  .dw-app {
    column-gap: 38px;
    svg {
      max-width: 100%;
    }
  }
  .app-img {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 250px;
    width: 100%;
    max-width: 649px;
    .MuiAvatar-root {
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }
}
.aboutus-wrap {
  padding: 145px 0px 30px 0px;
  p {
    color: #373737;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: 28px;
    margin: 0px;
    padding-top: 24px;
  }
  a {
    color: #405cfb;
  }
}
.subscription-wrap {
  padding: 30px 0px;
  .subscription-box-wrap {
    padding: 31px 0px 39px 0px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
    row-gap: 50px;
  }
  .subscripion-box {
    border-radius: 20px;
    padding: 40px 24px 40px 28px;
    min-height: 480px;
    h3 {
      color: #373737;
      padding: 46px 0px 28px 0px;
    }
    p {
      margin: 0px;
      line-height: 28px;
      font-size: 20px;
    }
  }
  .bg-blue {
    background: #41a6e91a;
  }
  .bg-green {
    background: #00d9091a;
  }
  .bg-yellow {
    background: #fe89001a;
  }
  .bg-purple {
    background: #9e00d81a;
  }
}
.testimonial-wrap {
  position: relative;
  padding: 30px 0px 90px 0px;
  .testimonial-slider-wrap {
    padding: 36px 0px 30px 0px;
    .slick-track {
      display: flex;
    }
  }
  .testimonial-content {
    padding: 10px 24px 28px 24px;
    border-radius: 4px;
    background: #fafafa;
    border-bottom: 4px solid #41a6e9;
    margin-right: 20px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: top;
    }
    .testimonial-user {
      display: block;
      text-align: center;
      padding: 11px 0px 10.8px 0px;
      font-family: "Raleway", sans-serif;
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      color: #000000;
    }
    .MuiRating-root {
      display: flex;
      justify-content: center;
      font-size: 15.74px;
      color: #41a6e9;
      margin: 0px auto;
    }
    .MuiRating-icon {
      color: #41a6e9;
    }
    p {
      font-size: 14px;
      font-family: "Raleway", sans-serif;
      line-height: 22px;
      color: #494949;
      padding-top: 20.5px;
      margin: 0px;
      text-align: center;
    }
  }
  .slick-arrow:before {
    display: block;
    content: "";
    background: url("../../../assets/images/icons/arrow-dropleft.svg");
    width: 36px;
    height: 36px;
    opacity: 1;
    background-size: 36px;
    background-repeat: no-repeat;
  }
  .slick-prev,
  .slick-next {
    width: auto;
    height: auto;
    z-index: 999;
  }
  .slick-prev {
    left: -110px;
  }
  .slick-next {
    right: -110px;
  }
  .slick-prev:before {
    transform: rotate(180deg);
  }
  ul.slick-dots {
    position: absolute;
    bottom: -43px;
  }
  .slick-dots li {
    margin: 0px 8.5px;
    width: 23px;
    height: 12px;
    padding: 0px;
  }
  .slick-dots li button {
    width: 12px;
    height: 12px;
    padding: 0px;
    font-size: 12px;
    line-height: 12px;
    background-color: #8b8b8b;
    opacity: 70%;
    border-radius: 50%;
    margin: auto;
    transition: all 0.3s;
    will-change: width;
  }
  .slick-dots li button:before {
    content: "";
  }

  .slick-dots li.slick-active button {
    background-color: #41a6e9;
    width: 23px;
    border-radius: 6px;
    opacity: 1;
  }
}
.contactus-wrap {
  padding: 17px 0px 33px 0px;
  .form-wrap {
    max-width: 440px;
    margin: 0 auto;
    padding-top: 22px;
    label {
      display: block;
      color: #373737;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      font-family: "Montserrat", sans-serif;
      padding-bottom: 8px;
    }
    .MuiFormControl-root {
      width: 100%;
      margin-bottom: 4px;
    }
    .MuiOutlinedInput-root {
      color: #373737;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      font-family: "Montserrat", sans-serif;
      background: #fff;
      margin-bottom: 4px;
    }
    input,
    textarea {
      border: 1px solid #d4d4d4;
      border-radius: 6px;
      padding: 4px 10px;
      max-width: 440px;
      width: -webkit-fill-available !important;
      outline: none;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      font-family: "Montserrat", sans-serif;
      color: #373737;
    }
    .MuiFormHelperText-root.Mui-error {
      color: #c01515;
      margin: 0 0 5px 0;
      font-size: 12px;
      line-height: 1;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .send_btn {
      font-size: 18px;
      width: 100%;
      padding: 11px 10px;
      margin-top: 17px;
    }
  }
}
.wp-wrap {
  padding: 39px 0px 65px 0px;
  .wp-inner {
    padding: 0px 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 86px;
    .wp-box {
      position: relative;
    }
    .wp-text-wrap {
      border: 1px solid #248f17;
      max-width: 447px;
      border-radius: 20px;
      overflow: hidden;
      span.text-badge {
        position: absolute;
        margin: 0px;
        background: #fff;
        color: #248f17;
        border-radius: 50%;
        max-width: 83px;
        max-height: 83px;
        font-size: 18px;
        line-height: 20px;
        height: 100%;
        text-transform: uppercase;
        font-weight: 500;
        display: flex;
        align-items: center;
        text-align: center;
        border: 2px solid #248f17;
        right: 12px;
        top: -15px;
      }
      .wp-text {
        background: #248f17;
        padding: 16px 20px 15px 20px;
        display: flex;
        align-items: center;

        .text-badge {
          position: absolute;
        }
      }
      span {
        margin-left: 13px;
        font-family: "Montserrat", sans-serif;
        font-size: 40px;
        color: #fff;
        font-weight: bold;
        line-height: 49px;
        letter-spacing: 3px;
      }
      .wp-number {
        padding: 12px 26px 13px 27px;
        span {
          font-family: "Montserrat", sans-serif;
          font-size: 49px;
          color: #113b0c;
          font-weight: bold;
          line-height: 59px;
          letter-spacing: 3px;
          letter-spacing: 3.62px;
          margin: 0px;
        }
      }
    }
    .wp-scan {
      display: flex;
      align-items: center;
      .scan-text span {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-transform: uppercase;
        color: #373737;
        display: flex;
        text-align: center;
      }
      .scan-text,
      .scan-arrow,
      .scan-code {
        display: flex;
      }
      .scan-text,
      .scan-code {
        align-items: center;
      }
      .scan-code {
        margin-left: auto;
        border-radius: 18px;
        border: 3px solid #41a6e9;
        width: 147px;
        height: 147px;
        img {
          padding: 8px;
          border-radius: 8px;
        }
      }
      .scan-arrow {
        align-self: start;
        svg {
          margin-top: 30px;
        }
      }
    }
  }
}
.sub-title {
  text-align: center;
  color: #41a6e9;
}
.bg-light-blue {
  background: #e9f6ff;
}
@media only screen and (max-width: 1366px) {
  .slider-wrap {
    padding: 90px 0;
    ul.slick-dots {
      bottom: -30px;
    }
  }
  .testimonial-wrap {
    .slick-prev {
      left: -55px;
    }
    .slick-next {
      right: -55px;
    }
  }
}
@media only screen and (max-width: 1279px) {
  .slider-wrap {
    padding: 80px 0;
    ul.slick-dots {
      bottom: -8px;
    }
    .banner_section {
      padding: 40px 0px;
      h1 {
        font-size: 40px;
        line-height: 50px;
      }
      .avtar_trust_block {
        padding-top: 40px;
      }
    }
    .banner-img-wrap {
      margin-right: 0px;
    }
  }
  .wp-wrap {
    .wp-inner {
      grid-template-columns: repeat(1, 1fr);
      .wp-box {
        max-width: 447px;
        margin: 0 auto;
        .wp-text-wrap {
          span.text-badge {
            right: -15px;
          }
        }
      }
      .wp-scan {
        max-width: 50%;
        margin: 20px auto;
        width: 100%;
        justify-content: space-around;
        .scan-code {
          margin-left: 0;
        }
      }
    }
  }
  .testimonial-wrap {
    .slick-prev {
      left: -10px;
    }
    .slick-next {
      right: -10px;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .slider-wrap {
    padding: 70px 0;
    ul.slick-dots {
      bottom: -8px;
    }
  }
  .subscription-wrap {
    .subscription-box-wrap {
      column-gap: 25px;
      row-gap: 30px;
      .subscripion-box {
        padding: 20px;
        h3 {
          font-size: 30px;
          line-height: 32px;
        }
      }
    }
  }
  .wp-wrap {
    .wp-inner {
      column-gap: 30px;
    }
  }
}
@media only screen and (max-width: 991px) {
  .aboutus-wrap {
    p {
      line-height: 26px;
      padding-top: 20px;
    }
  }
  .subscription-wrap {
    .subscription-box-wrap {
      padding: 30px 0px 20px 0px;
    }
  }
  .wp-wrap {
    .wp-inner {
      .wp-scan {
        max-width: 70%;
      }
    }
  }
  .slider-wrap {
    padding: 80px 0;
    .banner_section {
      h1 {
        font-size: 35px;
        line-height: 50px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .sm-title {
    h2 {
      font-size: 28px;
      line-height: 35px;
    }
  }
  .our-app-wrap {
    padding: 11px 15px 35% 15px;
    span {
      font-size: 20px;
    }
    .app-img {
      top: 190px;
    }
  }
  .subscription-wrap {
    .subscription-box-wrap {
      column-gap: 15px;
      row-gap: 30px;
      .subscripion-box {
        min-height: auto;
        h3 {
          font-size: 25px;
          line-height: 30px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
  .wp-wrap {
    .wp-inner {
      .wp-scan {
        max-width: 80%;
      }
    }
  }
  .slider-wrap {
    padding: 60px 0;
    .banner_section {
      h1 {
        font-size: 28px;
        line-height: 38px;
      }
      .btn_wrap {
        padding-top: 35px;
        .sample_test {
          padding: 12px !important;
        }
      }
    }

    .MuiGrid-root {
      flex-direction: column-reverse;
    }
    .slick-slide img {
      width: 80%;
      margin: 0 auto;
    }
    .banner-img-wrap,
    .banner-content-wrap {
      width: 100%;
      max-width: 100%;
    }
  }
}
@media only screen and (max-width: 599px) {
  .aboutus-wrap {
    p {
      line-height: 24px;
      padding-top: 18px;
    }
  }
  .slider-wrap {
    padding: 50px 0;
    .banner_section {
      padding: 25px 0px 40px 0px;
      .btn_wrap {
        padding-top: 20px;
      }
      .avtar_trust_block {
        padding-top: 25px !important;
        .trust_block {
          h5 {
            line-height: 18px !important;
          }
        }
      }
    }
    .slick-slide {
      img {
        width: 100%;
      }
    }
    .slick-dots {
      li {
        button:before {
          width: 10px;
          line-height: 10px;
          height: 10px;
          font-size: 10px;
        }
      }
    }
  }
  .subscription-wrap {
    .subscription-box-wrap {
      padding: 30px 0px 0px 0px;
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .wp-wrap {
    padding: 40px 0;
    .wp-inner {
      padding: 0px;
      .wp-box {
        width: 100%;
        .wp-text-wrap {
          .wp-number {
            padding: 10px;
            span {
              font-size: 30px;
            }
          }
          span {
            font-size: 22px;
          }
          .wp-text {
            padding: 10px;
          }
          span.text-badge {
            right: -9px;
            font-size: 15px;
            height: 65px;
            width: 65px;
            line-height: 15px;
          }
        }
      }
      .wp-scan {
        max-width: 100%;
        .scan-text span {
          font-size: 18px;
        }
      }
    }
  }
  .testimonial-wrap {
    padding: 30px 0 70px 0;
    .testimonial-content {
      margin-right: 0px;
    }
  }
  .our-app-wrap {
    .dw-app {
      column-gap: 15px;
    }
  }
}
@media only screen and (max-width: 440px) {
  .aboutus-wrap {
    padding: 100px 0px 20px 0px;
  }
  .wp-wrap {
    .wp-inner {
      .wp-scan {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .scan-arrow {
          align-self: center;
          transform: rotate(95deg);
        }
      }
      .wp-box {
        .wp-text-wrap {
          span {
            margin-left: 8px;
            letter-spacing: 1px;
          }
          .wp-number {
            span {
              font-size: 26px;
            }
          }
        }
      }
    }
  }
  .slider-wrap {
    padding: 40px 0;
    .banner_section {
      h1 {
        font-size: 22px;
        line-height: 30px;
      }
      .btn_wrap {
        .sample_test {
          margin-left: 12px;
        }
      }
      .avtar_trust_block {
        .trust_block {
          svg {
            width: 30px;
          }
          .MuiAvatarGroup-root {
            margin-left: 13.6px;
          }
          .img_avtar {
            width: 35px;
            height: 35px;
            margin-left: -13.6px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 375px) {
}
