@import "../../../assets/scss/variables.scss";

.cart_block_wraper{
    padding: 59px 0 254px;
    .empty_cart_block{
        padding-top: 46px;
        text-align: center;
        .empty_cart_title{
            font-size: 26px;
            font-weight: $font_medium;
            line-height: 32px;
            padding-top: 30px;
        }
        .browse_test{
            font-size: 20px;
            font-weight: $font_regular;
            line-height: 24px;
            padding: 38px 0 22px;
        }
    }
    .cart_total_block{
        background-color: $color-white-shade;
        padding: 18px;
        min-height: 460px;
        .semibold_text{
            font-weight: $font_semibold!important;
        }
        .orderSummery{
            font-size: 22.5px!important;
            line-height: 28px!important;
            font-weight: $font_medium!important;
            padding-bottom: 22px;
        }
        .sub_total{
            line-height: 19px!important;
            font-weight:$font_semibold!important;
            color: $color-light-gray!important;
        }
        .cart_total{
            color: $colorBlack!important;
        }
        .regular_font{
            font-weight:$font_regular!important;
        }
    }
    .payment_notice{
        font-size: 14px;
        font-weight: $font_regular;
        line-height: 18px;
        color: $color-light-black;
        margin: 0px 0px 19px 0;
    }
    .payment_card{
        padding: 0;
    }
    .payment_card:not(:last-child){
        padding-right: 16px;
    }
    .payment_card:hover{
        background: none;
    }
}


// responsive

@media(max-width:1535px){

}

@media (max-width:1199px){
    .cart_block_wraper{
        padding: 66px 0 200px;
        .empty_cart_block{
            .empty_cart_title{
                font-size: 22px;
                line-height: 28px;
                padding-top: 26px;
            }
            .browse_test{
                font-size: 18px;
                line-height: 22px;
                padding: 32px 0 20px;
            }
            .browse_test_btn{
                height: 50px;
                width: 250px;
            }
        }
        .cart_total_block{
            .cart_total{
                font-size: 16px!important;
                line-height: 20px!important;
            }
            .sub_total{
                font-size: 18px!important;
                line-height: 22px!important;
            }
            .or_text{
                line-height: 22px!important;
            }
        }
    }
}

@media(max-width:899px){
    .cart_block_wraper{
        padding: 48px 0 150px;
        .empty_cart_block{
            .empty_cart_title{
                font-size: 18px;
                line-height: 24px;
                padding-top: 22px;
            }
            .browse_test{
                font-size: 16px;
                line-height: 20px;
                padding: 28px 0 20px;
            }
            .browse_test_btn{
                height: 40px;
                width: 200px;
            }
        }
        .cart_total_block{
            padding: 26px 15px 22px;
            .cart_total{
                font-size: 14px!important;
                line-height: 18px!important;
            }
            .sub_total{
                font-size: 16px!important;
                line-height: 20px!important;
            }
            .or_text{
                line-height: 20px!important;
                margin: 11px 0 14px;
            }
        }
        .payment_notice{
            font-size: 14px;
            line-height: 18px;
            margin: 0px 0px 19px 0;
        }
    }
}

@media(max-width:599px){
    .cart_block_wraper{
        padding: 38px 0 100px;
        .empty_cart_block{
            .empty_cart_title{
                font-size: 16px;
                line-height: 22px;
                padding-top: 20px;
            }
            .browse_test{
                font-size: 14px;
                line-height: 18px;
                padding: 26px 0 18px;
            }
            .browse_test_btn{
                height: 35px;
                width: 170px;
            }
        }
        .cart_total_block{
            padding: 26px 15px 22px;
            .cart_total{
                font-size: 12px!important;
                line-height: 16px!important;
            }
            .sub_total{
                font-size: 14px!important;
                line-height: 18px!important;
            }
            .or_text{
                line-height: 18px!important;
                margin: 10px 0 12px;
            }
        }
        .payment_notice{
            font-size: 14px;
            line-height: 18px;
            margin: 0px 0px 19px 0;
        }
    }
}

.PrivatePickersFadeTransitionGroup-root{
    .css-1v994a0 {
        margin-right : 0 ;
    }
    .PrivatePickersYear-root button{
        width: auto ;
        font-size: 1rem ;
        line-height: 1.75 ;
    }
    .PrivatePickersMonth-root {
        font-size: 1rem ;
        line-height: 1.75 ;
    }
}