@import "../../../assets/scss/variables.scss";

.header_section {
  padding: 7px 48px 7px 10px;
  box-shadow: 0px 2px 20px 0px rgba($color: #000000, $alpha: 0.06);
  .show-899 {
    display: none;
  }
  .menu_wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .button-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .search_input {
      max-width: 223px;
    }
    .cart-btn {
      padding: 0 47px 0;
      &:hover {
        background: none;
      }
    }
    .user_block {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .user_name {
      font-size: 20px;
      font-weight: $font_medium !important;
      line-height: 24px;
      padding-right: 9px;
      width: 150px;
    }
    .sub_menu_wraper {
      position: absolute;
      background: #fff;
      padding: 42px 44px 46px 32px;
      border-radius: 20px;
      box-shadow: -2px -2px 16px 0px rgba($color: #000000, $alpha: 0.22);
      right: -12px;
      top: 58px;
      min-width: 276px;
      z-index: 2;
      .sub_menu_block {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          padding-bottom: 50px;
        }
        .sun_menu_name {
          padding: 0 0 0 20px;
          font-size: 26px !important;
          font-weight: $font_medium;
          line-height: 32px !important;
          color: #373737;
        }
      }
    }
  }
  .menu_link {
    font-size: 20px;
    font-weight: $font_regular;
    line-height: 24px;
    text-transform: capitalize;
    color: $color-light-black;
    text-decoration: none;
    &:hover {
      -webkit-text-fill-color: $color-skyblue;
      -webkit-text-stroke-width: 1.2px;
      -webkit-text-stroke-color: $color-skyblue;
      stroke-linecap: butt;
      stroke-linejoin: miter;
      stroke-opacity: 100%;
    }
    &.active {
      -webkit-text-fill-color: $color-skyblue;
      -webkit-text-stroke-width: 1.2px;
      -webkit-text-stroke-color: $color-skyblue;
      stroke-linecap: butt;
      stroke-linejoin: miter;
      stroke-opacity: 100%;
    }
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
}
.menu_bar {
  text-align: center;
  padding: 30px;
  height: 100%;
  .close_icon {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .nav_link {
    color: $color-white;
    padding: 15px 8px;
    font-size: 22px;
    text-transform: capitalize;
    font-weight: 400;
  }
  .show-599 {
    @media (min-width: "601px") {
      display: none;
    }
  }
}


.logout_btn {
  height: 38px !important;
  font-size: 16px !important;
  width: 80px !important;
}

.whatsapp_block {
  position: fixed;
  z-index: 2;
  top: 40%;
  right: 35px;
  text-align: center;
  .whatsapp_text {
    font-size: 14px;
    font-weight: $font_semibold;
    line-height: 18px;
  }
}
body .sub-item {
  color: #373737;
  font-size: 20px;
  line-height: 24px;
  font-family: $primary_font;
}
// responsive

@media (max-width: 1746px) {
  body .sub-item {
    font-size: 18px;
  }
  .header_section {
    .button-wrap {
      .search_input {
        max-width: 200px;
      }
      .cart-btn {
        padding: 0 40px 0 35px;
      }
    }
     .menu_link {
      font-size: 18px;
      &:not(:last-child) {
        margin-right: 21px;
      }
    }
  }
}

@media (max-width: 1690px) {
  .header_section {
    .button-wrap {
      .sub_menu_wraper {
        .sub_menu_block {
          .sun_menu_name {
            font-size: 20px !important;
          }
        }
        .sub_menu_block:not(:last-child) {
          padding-bottom: 30px;
        }
      }
      .search_input {
        max-width: 170px;
        .MuiOutlinedInput-input {
          padding: 13.5px 14px 13.5px 0;
        }
      }
      .cart-btn {
        padding: 0 25px 0 20px;
        svg {
          width: 30px;
          height: 27px;
        }
      }
    }
  }
}

@media (max-width: 1605px) {
  body .sub-item {
    font-size: 16px;
  }
  .header_section {
    .button-wrap {
      .search_input {
        max-width: 150px;
      }
    }
        .menu_link {
      font-size: 16px;
      &:not(:last-child) {
        margin-right: 18px;
      }
    }
  }
}

@media (max-width: 1440px) {
  body .sub-item {
    font-size: 14px;
  }
  .header_section {
    padding: 7px 20px 7px 15px;
    .button-wrap {
      .search_input {
        max-width: 130px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .cart-btn {
        padding: 0 10px 0;
      }
    }
    .menu_wrap {
      .MuiButton-root {
        &:not(:last-child) {
          margin-right: 14px;
        }
      }
    }
  }
}

@media (max-width: 1279px) {
  .header_section {
    padding: 7px 35px 7px 25px;
    .button-wrap {
      .icon_wrap {
        align-items: center;
        .menu_icon {
          display: flex;
          margin-right: 15px;
          svg {
            rect {
              fill: #4798fc;
            }
          }
        }
        .search_icon {
          display: flex;
          align-items: center;
          margin-right: 15px;
          svg {
            width: 20px;
            height: 20px;
          }
        }
        .cart_icon {
          display: flex;
          svg {
            width: 24px;
            height: 24px;
          }
        }
        .login_icon {
          display: flex;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}
@media (max-width: 899px) {
  .header_section {
    padding: 7px 20px 7px 20px;
  }
  .search_icon.hide-899 {
    display: none !important;
  }
  .search_icon.show-899 {
    display: block;
    .search_input {
      width: 100%;
      margin: 10px 0;
      margin-left: 0;
      .MuiOutlinedInput-input {
        padding: 5px;
      }
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

@media (max-width: 600px) {
  .whatsapp_block {
    right: 5px;
  }
  .header_section {
    padding: 4px 15px;
    }
}